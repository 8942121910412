import React, { useState, useEffect, useRef } from "react";
import {
  linkCredentialsAction,
  connectProviderAction,
  validateEmailAction,
} from "modules/auth/actions";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { H1, P1, P2 } from "app/components/Typography";
import Button from "app/components/Button";
import Alert from "app/components/Alert";
import InputEmail from "app/components/Input/InputEmail";
import InputPassword from "app/components/Input/InputPassword";
import { Formik, Form } from "formik";
import queryString from "query-string";
import { DATA_TEST_ID } from "constants/index";
import env from "helpers/env";
import Icon, { Facebook, GoogleLogoWithColor } from "app/components/Icon";
import { incrementStep } from "app/routes/Register/helpers";
import {
  Wrapper,
  ProviderButton,
  ProviderButtonText,
  StyledCtaLink,
  CtaWrapper,
  A,
} from "./styles";
import { CreateAccountWrapper } from "./CreateAccountWrapper";
import { RESIZE_WIDTHS } from "../../constants";

export function CreateAccount() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, toggleLoading] = useState(false);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const isLastStep = activeStep === 1;
  const emailValidated = () => setActiveStep(activeStep + 1);

  useEffect(() => {
    if (!isLastStep) {
      emailInputRef.current.focus();
    } else {
      passwordInputRef.current.focus();
    }
  }, [activeStep]);

  const handleSubmit = values => {
    setError(null);

    if (!isLastStep) {
      dispatch(validateEmailAction(values, emailValidated))
        .then(() => {
          incrementStep({ stepsToIncrement: 1, location, history });
        })
        .catch(err => {
          setError(err);
          toggleLoading(false);
        });
    } else {
      toggleLoading(true);
      dispatch(linkCredentialsAction(values, {}, redirectOnSuccess))
        .then(() => {
          incrementStep({ stepsToIncrement: 1, location, history });
        })
        .catch(err => {
          setError(err);
          toggleLoading(false);
        });
    }
  };

  const linkProvider = provider => {
    toggleLoading(true);
    dispatch(connectProviderAction(provider, {}, redirectOnSuccess)).catch(
      err => {
        setError(err);
        toggleLoading(false);
      }
    );
  };

  const redirectOnSuccess = () => {
    const query = queryString.parse(location.search);

    // Need to remove step param that is tranferred from Register
    delete query.step;

    const params = `?${queryString.stringify(query)}`;
    history.push(`/created-account${params}`);
  };

  return (
    <CreateAccountWrapper>
      <Wrapper data-test-id={DATA_TEST_ID.onboarding.createAccountWrapper}>
        <Div width="100%">
          <H1
            fontWeight="bold"
            fontSize={{ _: "24px", lg: "64px" }}
            textTransform={{ _: "none", lg: "capitalize" }}
            mb={{ _: "12px", lg: "20px" }}
          >
            {isLastStep
              ? "Set a password for your account"
              : "Sign up to save your recommendations!"}
          </H1>
          <H1
            fontWeight="bold"
            fontSize={{ _: "14px", lg: "32px" }}
            color="blue"
            mb="24px"
          >
            {isLastStep
              ? "Easy to remember, but still secure"
              : "What's your email?"}
          </H1>
          <Div maxWidth={RESIZE_WIDTHS.md}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmPassword: "",
              }}
              onSubmit={handleSubmit}
              render={({ touched, errors, handleChange, isValid }) => (
                <Form>
                  {activeStep === 0 && (
                    <>
                      <Div mb={4}>
                        <InputEmail
                          innerRef={emailInputRef}
                          onChange={handleChange}
                          errors={errors}
                          enableLabel
                          touched={touched}
                          required
                          isSignUp
                        />
                      </Div>
                      <Button
                        mb={4}
                        letterSpacing="0.04em"
                        width="100%"
                        height="44px"
                        disabled={loading || !isValid}
                      >
                        Continue
                      </Button>
                      <Flex flexDirection="column">
                        <P1 mb={3}>or sign up with:</P1>
                        <ProviderButton
                          mb={3}
                          width="100%"
                          height="44px"
                          onClick={() => linkProvider("google")}
                        >
                          <Icon
                            mr={2}
                            width="20px"
                            height="20px"
                            as={GoogleLogoWithColor}
                          />
                          <ProviderButtonText>
                            Sign up with Google
                          </ProviderButtonText>
                        </ProviderButton>
                        <ProviderButton
                          mb={3}
                          width="100%"
                          height="44px"
                          onClick={() => linkProvider("facebook")}
                        >
                          <Icon
                            mr={2}
                            width="20px"
                            height="20px"
                            bg="facebook"
                            as={Facebook}
                          />
                          <ProviderButtonText>
                            Sign up with Facebook
                          </ProviderButtonText>
                        </ProviderButton>
                      </Flex>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <InputPassword
                        innerRef={passwordInputRef}
                        name="password"
                        id="password"
                        onChange={handleChange}
                        errors={errors}
                        enableLabel
                        touched={touched}
                        required
                        isSignUp
                      />
                      <Div
                        width="100%"
                        mt={3}
                        mb="24px"
                        textAlign={{ _: "center", lg: "left" }}
                      >
                        <P2 color="monochrome.4" letterSpacing="0.04em">
                          By signing up I agree to STEEZY&apos;s <br />
                          <A href={`${env("PUBLIC_URL")}/terms`} target="blank">
                            Terms of Service
                          </A>{" "}
                          &amp;{" "}
                          <A
                            href={`${env("PUBLIC_URL")}/privacy`}
                            target="blank"
                          >
                            Privacy Policy
                          </A>
                          .
                        </P2>
                      </Div>
                      <Button
                        mb="40px"
                        letterSpacing="0.04em"
                        width="100%"
                        height="44px"
                        disabled={loading || !isValid}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                  {error && (
                    <Alert variant="danger" closeAlert={() => setError(null)}>
                      {error.message}
                    </Alert>
                  )}
                  {success && (
                    <Alert
                      variant="success"
                      closeAlert={() => setSuccess(null)}
                    >
                      {success.message}
                    </Alert>
                  )}
                </Form>
              )}
            />
            <CtaWrapper>
              <span>Already have an account?</span>
              <span>
                <StyledCtaLink to="/login">Sign In</StyledCtaLink>
              </span>
            </CtaWrapper>
          </Div>
        </Div>
      </Wrapper>
    </CreateAccountWrapper>
  );
}
